import React, { PureComponent } from 'react';
import { RestaurantBlockContainer, RestaurantBlockContainerHeadingOne,
  RestaurantBlockContainerHeadingSix } from './ResturantBlock.styles';
import { graphql, StaticQuery } from 'gatsby';

class RestaurantBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {

    const { data } = this.props;
    const {
      subNoteBlockTitle,
      subNoteBlockDescription,
      subNoteBlockImage,
    } = data.markdownRemark.frontmatter.subNoteSection;

    if (this.state.hasError) {
      return <h1>Well then look here, that means everything failed. You Win a prize!!</h1>;
    }
    return (
      <div className="ResturantBlockWrapper">
        <div id="resturant_block" className="re" style={{paddingTop: '10vw', paddingBottom: '10vw'}}>
          <div className="container">
              <div className="row">
                  <div className="col-md-6">
                      <RestaurantBlockContainer>
                          <RestaurantBlockContainerHeadingSix data-aos="fade-right" data-aos-delay="250">{subNoteBlockTitle}</RestaurantBlockContainerHeadingSix>
                          <RestaurantBlockContainerHeadingOne data-aos="fade-right" data-aos-delay="500">{subNoteBlockDescription}<br/></RestaurantBlockContainerHeadingOne>
                      </RestaurantBlockContainer>
                  </div>
                  <div className="col-md-6" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><a href="//brixbrine.com" style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}><img data-aos="fade-left" data-aos-delay="250" src={subNoteBlockImage.publicURL} alt="brix" width="40%"/></a></div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            subNoteSection {
                subNoteBlockTitle
                subNoteBlockDescription
                subNoteBlockImage {
                    publicURL
                }
            }
          }
        }
      }
    `}
    render={data => <RestaurantBlock data={data} {...props} />}
  />
);


