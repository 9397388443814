import styled from 'styled-components'

export const ColumnModifier = styled.div`
  text-align: center;
  display: inline-block;
`

export const AnnouncementContainer = styled.div`
  display: block;
  text-align: center;
`

export const AnnouncementContainerLink = styled.a`
    background-color: rgba(0,0,0,0);
    border-color: rgba(0,0,0,0);
    box-shadow: 0 0 0 .2rem rgba(0,0,0,0);
    color: #fff;
    
    &:hover, &:before, &:focus, &:active  {
        color: #fff;
        background-color: rgba(0, 0, 0, 0) !important;
        border-color: rgba(0, 0, 0, 0) !important;
    }

`

export const NotificationContainer = styled.div`
  background-color: rgb(35, 35, 40);
    color: white;
    padding: 24px 15px 10px;
    text-align: center;
    z-index: 12000;
    top: 0;
    
`;


export const WhiteWrapper = styled.a`
    color: white;
    
    &:hover {
        color: #cccfd2;
    }
`

export const WhiteButtonWrapper = styled.button`
    color: white;
    background-color: rgba(0,0,0,0);
     background: none!important;
     border: none;
     padding: 0!important;
     /*optional*/
     font-family: arial, sans-serif;
    /*input has OS specific font-family*/
     text-decoration: underline;
     cursor: pointer;

`