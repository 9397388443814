import styled from 'styled-components';

export const ReserveHeadingOne = styled.h1`
  font-size: 1em;
  font-family: 'PT Serif', serif;
`;

export const ReserveHeadingOneVersionOne = styled.h1`
  font-size: 2em;
  font-family: 'Poppins', sans-serif;
  width: 60%;
  margin: 0 auto;
`;

export const ReserveParagraph = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #18ffc7;
`;

export const ReserveParagraphVersion1 = styled.p`
  font-family: 'Karla', sans-serif;
  color: #18ffc7 !important;
  margin-bottom: 0;

  strong {
    color: #18ffc7;
  }
`;

export const ReserveParagraphVersion2 = styled.p`
  font-family: 'Karla', sans-serif;
  margin-bottom: 0;
  color: white;

  strong {
    color: #18ffc7;
  }
`;

export const OpenTableLinkage = styled.div`
  display: block;
`;

export const OpenTableLink = styled.a`
  background-color: #fe6273;
  margin: 20px;
  border-color: #fe7080;
  box-shadow: 0 0 0 0.2rem #fe6273;
  color: white;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;

  &:active {
    color: #fff;
    border-color: #f63451;
    box-shadow: 0 0 0 0.2rem #f63451;
  }

  &:hover {
    color: #fff;
    border-color: #f63451;
    box-shadow: 0 0 0 0.2rem #f63451;
  }


`;

export const HoursTable = styled.table`
  width: 80%;
`;


export const TextRightColumn = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  @media (min-width: 1200px) {
    text-align: left;
    padding-left: 1vw;
    margin-bottom: 0;
  }
`;

export const TextLeftColumn = styled.div`
  text-align: center;
  margin-bottom: 5vw;
  @media (min-width: 1200px) {
    text-align: right;
    padding-right: 1vw;
    margin-bottom: 0;
  }
`;
