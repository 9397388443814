import React, { PureComponent } from 'react';
import { AboutIntroBlock, AboutIntroButton, AboutIntroIStyling,
  AboutIntroTextSpan, IntroBlockColumn, IntroContainer ,
  IntroContainerBlock, VideoContainerBlock ,
  VideoContainerBlockButton } from './IntroBlock.styles';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { graphql, StaticQuery } from 'gatsby';

class IntroBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      isShowingPlayButton: true,
      isPlayingVideo: false,
    };
  }

  openVideo = () => {
    // toggle the active boolean in the state
    let video = this.refs.video_component;
    this.setState(
      {
        isShowingPlayButton: !this.state.isShowingPlayButton
      },
    );
    if (this.state.isShowingPlayButton) {
      video.play();
      video.setAttribute("controls","controls")
    } else {
      video.pause();
      video.removeAttribute("controls")
    }
  };


  render () {
    const { data } = this.props;
    const {
      introBlockVideoToggle,
      introBlockVideoTitle,
      introBlockVideoSubTitle,
      introBlockSegueTitle,
      introBlockVideoContentToggle,
      introBlockYoutubeVideLink,
      introBlockLocalVideoLink,
      introBlockImage,
    } = data.markdownRemark.frontmatter.introBlockSection;

    const regexStr = 'https://www.youtube.com/embed/.+?(?="|$)'; // this receives the whole url
    const regex = new RegExp(regexStr);
    let obj = regex.exec(introBlockYoutubeVideLink);


    const youtubeVideoContainer = (
      <div style={{ paddingTop: "56.25%" }}>
        <iframe
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
          }}
          src={obj}
          frameBorder="0"
          title="Youtube Video"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );

    const localVideoContainer = (
      <div className="col-md-12" style={{ position: 'relative' }}>
        { this.state.isShowingPlayButton ?
          <VideoContainerBlock data-aos="fade-up" data-aos-delay="100">
            <VideoContainerBlockButton className="btn btn-primary" onClick={() => this.openVideo()} type="button" id="video_bar">
              <i className="fa fa-play"/>
            </VideoContainerBlockButton>
          </VideoContainerBlock>
          : <div/>
        }
        <video data-aos="fade-up" ref="video_component" id="video_component" width="100%" height="100%"
               src={introBlockLocalVideoLink}>
          <track src="item.vtt" kind="captions" srcLang="en" label="english_captions"/>
        </video>
      </div>
    );

    if (this.state.hasError) {
      return <h1>Well that's unfortunate</h1>;
    }
    return (
      <div className="IntroBlockWrapper">
        { introBlockVideoToggle ? <div>
          <IntroContainer>
            <IntroContainerBlock id="intro_block">
              <div className="container">
                <div className="row" id="intro_row_class">
                  <IntroBlockColumn className="col-md-12">
                    <div style={{color: '#3c4146'}}>
                      <h1 id="section_1_heading">{introBlockVideoTitle}</h1>
                      <h1 id="section_1_heading" style={{fontWeight: '500', paddingBottom: '40px', fontSize: '3.0em'}}>{introBlockVideoSubTitle}</h1>
                      <AnchorLink href="#about_block" style={{width: '100%'}}>
                        <div>
                          <AboutIntroBlock/>
                          <AboutIntroTextSpan>{introBlockSegueTitle}</AboutIntroTextSpan>
                          <AboutIntroButton className="btn btn-primary" role="button" href="#about_block">
                            <AboutIntroIStyling className="fa fa-long-arrow-down"/>
                          </AboutIntroButton>
                        </div>
                      </AnchorLink>
                    </div>
                  </IntroBlockColumn>
                </div>
              </div>
            </IntroContainerBlock>
          </IntroContainer>
          <div className="container" style={{paddingBottom: "10vw"}}>
            <div className="row">
              { introBlockVideoContentToggle ? youtubeVideoContainer : localVideoContainer}
            </div>
          </div>
        </div> :
          <div id="Header_Component">
            <div className="container" style={{paddingBottom: "4vw"}}>
              <div className="row">
                <div className="col">
                  <div style={{display: "block",textAlign: "center"}}>
                    <img id="container_images_compoenent" alt="The Crooked Pine building" src={introBlockImage.publicURL} width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            introBlockSection {
              introBlockVideoToggle
              introBlockVideoTitle
              introBlockVideoSubTitle
              introBlockSegueTitle
              introBlockVideoToggle
              introBlockYoutubeVideLink
              introBlockLocalVideoLink
              introBlockImage {
                publicURL
              }
            }
          }
        }
      }
    `}
    render={data => <IntroBlock data={data} {...props} />}
  />
);
