import styled from 'styled-components';

export const HeadingValueOne = styled.h1`
  font-size: 1.2em;
  font-family: 'PT Serif', serif;
`;

export const HeadingValueOneVersionOne = styled.h1`
  font-size: 2em;
  font-family: 'Poppins', sans-serif;
`;

export const FoodContainer = styled.div`
  margin: 0 20px;
  border-bottom: 1px solid rgb(209, 209, 209);
  border-top: 1px solid rgb(209, 209, 209);
`;

export const FoodContainerHeadingOne = styled.h1`
  font-weight: 100;
  letter-spacing: -0.035em;
  line-height: 1.11538;
  text-align: center;
  color: #1f1f1f;
  margin: 1.25em 0;
  text-decoration-color: #18ffc7;
  font-size: 3em;
  font-family: 'PT Serif', serif;
`;

export const FoodContainerVersionOneHeading = styled.h1`
  font-weight: 100;
  letter-spacing: -0.035em;
  line-height: 1.11538;
  text-align: center;
  margin: 1.25em 0;
  color: #1f1f1f;
  text-decoration-color: #18ffc7;
  font-size: 3em;
  font-family: 'PT Serif', serif;
`;

export const WineContainer = styled.div`
  font-family: 'Merriweather', serif;
  font-weight: 300;
  padding: 30px 0;
`;

export const WineContainerHeadingSix = styled.h6`
  font-weight: 300;
  font-size: 2em;
  font-family: 'Poppins', sans-serif;
`;

export const WineContainerHeadingOne = styled.h1`
  color: #9ea0a6;
  font-weight: 300;
  font-size: 1.5em;
  font-family: 'PT Serif', serif;
`;

export const DescriptionParagraphStyle = styled.p`
  font-family: 'PT Serif', serif;
  font-size: 1.4em;
  line-height: 2;
  padding: 20px 0;
`;
