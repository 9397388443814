import React, { PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { HeadingValueOne, DescriptionParagraphStyle, FoodContainer,
  FoodContainerHeadingOne, FoodContainerVersionOneHeading,
  HeadingValueOneVersionOne, WineContainer, WineContainerHeadingOne,
  WineContainerHeadingSix } from './MenuBlock.styles';



class MenuBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {
    const { data } = this.props;
    const {
      menuTitle,
      menuTitleDescription,
      menu1Title,
      menu1Menu,
      menu1Enabled,
      menu2Title,
      menu2Menu,
      menu2Enabled,
      menu3Title,
      menu3Menu,
      menu3Enabled,
      menu4Title,
      menu4Menu,
      menu4Enabled,
      menuWineShowcaseImage ,
      menuWineSectionTitle,
      menuWineSectionSubtitle,
      menuWineSectionDescription,
      menuWineSectionDescriptionItem1,
      menuWineSectionDescriptionItem2,
      menuWineSectionDescriptionItem3,
      menuWineSectionDescriptionItem4,
      menuWineSectionDescriptionItem5,
    } = data.markdownRemark.frontmatter.menuBlockSection;

    if (this.state.hasError) {
      return <h1>Time to contact NASA</h1>;
    }
    return (
      <div className="MenuBlockWrapper">
        <div id="menu_block" style={{ backgroundColor: '#FFF' }}>
          <div className="container" style={{ padding: '10vw 0vw' }}>
            <div className="row" style={{ paddingTop: '20px' }}>
              <div className="col">
                <HeadingValueOne className="text-center" data-aos="fade-up">
                  {menuTitle}
                </HeadingValueOne>
                <HeadingValueOneVersionOne
                  className="text-center"
                  data-aos="fade-up"
                  data-aos-duration="1100"
                >
                  {menuTitleDescription}
                </HeadingValueOneVersionOne>
              </div>
            </div>
            <div className="row" style={{ padding: '10vw 0vw', margin: '0 auto' }}>
              <div
                className="col-md-6 col-xl-12"
                id="menu_columns_handler"
                style={{ minWidth: '100%', margin: '0 auto' }}
              >
                { menu1Enabled  ? <FoodContainer data-aos="fade-up" data-aos-delay="100">
                  <a href={menu1Menu.publicURL}>
                    <FoodContainerHeadingOne>{menu1Title}</FoodContainerHeadingOne>
                  </a>
                </FoodContainer> : <div/>}
                { menu2Enabled ? <FoodContainer data-aos="fade-up" data-aos-delay="250">
                  <a href={menu2Menu.publicURL}>
                    <FoodContainerVersionOneHeading>{menu2Title}</FoodContainerVersionOneHeading>
                  </a>
                </FoodContainer> : <div/>}
                { menu3Enabled ? <FoodContainer data-aos="fade-up" data-aos-delay="500">
                  <a href={menu3Menu.publicURL}>
                    <FoodContainerHeadingOne>{menu3Title}</FoodContainerHeadingOne>
                  </a>
                </FoodContainer> : <div/>}
                { menu4Enabled ? <FoodContainer data-aos="fade-up" data-aos-delay="750">
                  <a href={menu4Menu.publicURL}>
                    <FoodContainerVersionOneHeading>{menu4Title}</FoodContainerVersionOneHeading>
                  </a>
                </FoodContainer> : <div/>}
              </div>
            </div>
            <div className="row" style={{marginBottom: "150px"}}>
              <div className="col">
                <img
                  data-aos="fade-up"
                  id="image_menu_container"
                  src={menuWineShowcaseImage.publicURL}
                  alt="glass"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6" style={{ fontWeight: '300' }}>
                <WineContainer>
                  <WineContainerHeadingSix data-aos="fade-right" data-aos-delay="250">
                    {menuWineSectionTitle}
                  </WineContainerHeadingSix>
                  <WineContainerHeadingOne data-aos="fade-right" data-aos-delay="500">
                    {menuWineSectionSubtitle}
                    <br />
                  </WineContainerHeadingOne>
                </WineContainer>
              </div>
              <div className="col">
                <DescriptionParagraphStyle data-aos="fade-left" data-aos-delay="500">
                  {menuWineSectionDescription}
                </DescriptionParagraphStyle>
                <DescriptionParagraphStyle data-aos="fade-left" data-aos-delay="600">
                  {menuWineSectionDescriptionItem1}
                </DescriptionParagraphStyle>
                <DescriptionParagraphStyle data-aos="fade-left" data-aos-delay="700">
                  {menuWineSectionDescriptionItem2}
                </DescriptionParagraphStyle>
                <DescriptionParagraphStyle data-aos="fade-left" data-aos-delay="800">
                  {menuWineSectionDescriptionItem3}
                </DescriptionParagraphStyle>
                <DescriptionParagraphStyle data-aos="fade-left" data-aos-delay="900">
                  {menuWineSectionDescriptionItem4}
                </DescriptionParagraphStyle>
                <DescriptionParagraphStyle data-aos="fade-left" data-aos-delay="1000">
                  {menuWineSectionDescriptionItem5}
                </DescriptionParagraphStyle>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            menuBlockSection {
              menuTitle
              menuTitleDescription
              menu1Title
              menu1Menu {
                publicURL
              }
              menu1Enabled
              menu2Title
              menu2Menu {
                publicURL
              }
              menu2Enabled
              menu3Title
              menu3Menu {
                publicURL
              }
              menu3Enabled
              menu4Title
              menu4Menu {
                publicURL
              }
              menu4Enabled
              menuWineShowcaseImage {
                publicURL
              }
              menuWineSectionTitle
              menuWineSectionSubtitle
              menuWineSectionDescription
              menuWineSectionDescriptionItem1
              menuWineSectionDescriptionItem2
              menuWineSectionDescriptionItem3
              menuWineSectionDescriptionItem4
              menuWineSectionDescriptionItem5
            }
          }
        }
      }
    `}
    render={data => <MenuBlock data={data} {...props} />}
  />
);
