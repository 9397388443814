import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

const AboutBlockGroup = styled.div`
  height:  auto;
  z-index: 20;
  padding: 20px;
`;

const AboutBlockContainer = styled.div`
  padding: 0 0 5vw;
  background-color: #fafafa;
`;

const AboutBlockFontStyle = styled.div`
  font-family: 'Merriweather', serif;
  font-weight: 300;
  padding: 30px 0;
`;

const AboutFontBlockHeadingOne = styled.h1`
  color: #9ea0a6;font-weight: 300;font-size: 1.5em;font-family: 'PT Serif', serif;
`;

const AboutFontHeadingSix = styled.h6`
  font-weight: 300;font-size: 2.0em;font-family: 'Poppins', sans-serif;
`;

const AboutFontParagraph = styled.p`
  font-family: 'PT Serif', serif;font-size: 1.4em;line-height: 2;padding: 20px 0px;
`;

class AboutBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {
    const { data } = this.props;
    const {
      aboutTitle,
      aboutSubTitle,
      aboutDescription,
      aboutImage,
      aboutImageToggle,
    } = data.markdownRemark.frontmatter.aboutBlockSection;

    if (this.state.hasError) {
      return <h1>Well this is worse..</h1>;
    }
    return (
      <div className="AboutBlockWrapper">
        <AboutBlockGroup id="about_block">
          <AboutBlockContainer className="container">
              <div className="row">
                  <div className="col-md-6" style={{fontWeight: 300}}>
                      <AboutBlockFontStyle>
                          <AboutFontHeadingSix data-aos="fade-right" data-aos-delay="500">{aboutTitle}</AboutFontHeadingSix>
                          <AboutFontBlockHeadingOne data-aos="fade-right" data-aos-delay="750">{aboutSubTitle}<br /></AboutFontBlockHeadingOne>
                      </AboutBlockFontStyle>
                  </div>
                  <div className="col">
                      <AboutFontParagraph data-aos="fade-left" data-aos-delay="1000">{aboutDescription}</AboutFontParagraph>
                  </div>
              </div>
              { aboutImageToggle ? <div className="row" style={{paddingTop: '10vw'}}>
                <div className="col-xl-12">
                  <img alt="About Content" data-aos="fade-up" style={{width: '100%', height: '60vh', objectFit: 'cover', objectPosition: "center top"}} src={aboutImage.publicURL}/>
                </div>
                </div> :  <div/> }
          </AboutBlockContainer>
        </AboutBlockGroup>
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            aboutBlockSection {
                aboutTitle
                aboutSubTitle
                aboutDescription
                aboutImage {
                    publicURL
                }
                aboutImageToggle
            }
          }
        }
      }
    `}
    render={data => <AboutBlock data={data} {...props} />}
  />
);
