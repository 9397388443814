import React, { PureComponent } from 'react';
import { FormGroupButton, ContactUsHeading, FormGroupInput, FormGroupInputVersion1,
  FormGroupParagraph , FormGroupSelect, FormGroupTextArea , FormHeading, FormGroupItal, FormDescriptionHeader
} from "./ContactBlock.styles";
import { graphql, StaticQuery } from 'gatsby';


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactBlock extends PureComponent  {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "" };
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    if (this.handleValidation) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state })
      })
        .then(() => this.handleAnimation())
        .catch(error => alert(error));

      e.preventDefault();
    }
  };

  handleAnimation = () => {
    alert("Form Submitted Successfully, now refreshing")
    window.location.reload()
  }

  handleValidation = () => {
    let formIsValid = true;
    const holder = {...this.state}

    /// Form Is Empty
    if(JSON.stringify(holder) === '{}') {
      alert("One or more fields are not valid")
      return false
    }

    if(!holder["Full Name"] || !holder["Email"] || !holder["Type Of Message"] || !holder["Contact Number"] ||  !holder["User Message"]) {
      alert("One or more fields are not valid")
      return false
    }

    // Full Name
    if(typeof holder["Full Name"] !== "undefined"){
      if(!holder["Full Name"].match(/^[\w'\-,.]*[^_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/)){
        alert("Invalid Name")
        return false;
      }
    }

    // Email
    if(typeof holder["Email"] !== "undefined"){
      let lastAtPos = holder["Email"].lastIndexOf('@');
      let lastDotPos = holder["Email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && holder["Email"].indexOf('@@') === -1 && lastDotPos > 2 && (holder["Email"].length - lastDotPos) > 2)) {
        alert("Invalid Email")
        return false;
      }
    }

    // Contact #
    if(typeof holder["Contact Number"] !== "undefined"){
      if(!holder["Contact Number"].match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im)){
        alert("Invalid Phone Number")
        return false;
      }
    }
    return formIsValid;
  }

  render () {
    const { data } = this.props;
    const {
      contactUsTitle,
      contactUsSubTitle,
      contactUsSecondaryTitle,
      contactUsSecondarySubTitle,
      contactUsSecondaryEmail,
      contactUsImage,
    } = data.markdownRemark.frontmatter.contactBlockSection;

    return (
      <div className="container" style={{color: '#1f1f1f', backgroundColor: '#fafafa'}}>
        <div className="row" id="Contact_Block" style={{paddingTop: '10vw', paddingBottom: '10vw'}}>
          <div className="col">
            <ContactUsHeading data-aos="fade-up" data-aos-delay="50" className="text-center">{contactUsTitle}</ContactUsHeading>
            <h1 data-aos="fade-up" data-aos-delay="200" className="text-center" id="contact_us_header">{contactUsSubTitle}<br/></h1>
          </div>
        </div>
        <div className="row" style={{height: 'auto', paddingBottom: '5vw'}}>
          <FormDescriptionHeader className="col" style={{textAlign: "center"}}>
            <h1 id="contact_us_secondary_header" data-aos="fade-up" data-aos-delay="225" style={{textAlign: 'center'}}>{contactUsSecondaryTitle}<br/></h1>
            <FormHeading data-aos="fade-up" data-aos-delay="250">{contactUsSecondarySubTitle}<a href="mailto:jakenkirsch@gmail.com?subject=Hi The Crooked Pine!%20%E2%9C%8B" style={{color: '#8e9396'}}>{contactUsSecondaryEmail}</a><br/></FormHeading>
            <div style={{paddingTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><img data-aos="fade-up" data-aos-delay="300" alt="side piece" src={contactUsImage.publicURL} style={{width: '50%'}}/></div>
          </FormDescriptionHeader>
          <div className="col-md-6 col-xl-5">
            <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/" onSubmit={this.handleSubmit}>
              <input type="hidden" aria-label="hidden" name="form-name" value="contact"/>
              <FormGroupParagraph>Full name *</FormGroupParagraph>
              <FormGroupInput className="form-control" type="text" required="" placeholder="John Doe" onChange={this.handleChange} name="Full Name"/>
              <FormGroupParagraph>Email&nbsp;*</FormGroupParagraph>
              <FormGroupInput className="form-control" type="text" required="" placeholder="hi@brixbrine.com" onChange={this.handleChange} name="Email"/>
              <FormGroupParagraph>Subject *</FormGroupParagraph><FormGroupSelect className="form-control" onChange={this.handleChange} name="Type Of Message" defaultValue={"disabled"}><option value="disabled" disabled>Please Select</option><option value="Reservation">Reservation</option><option value="Private Event">Private Event</option><option value="Other">Other</option></FormGroupSelect>
              <FormGroupParagraph>Phone number *</FormGroupParagraph>
              <FormGroupInputVersion1 className="form-control" type="tel" placeholder="(207)-641-2971" required="" minlength="9" maxlength="13" inputmode="tel" onChange={this.handleChange} name="Contact Number"/>
              <FormGroupParagraph>Message &nbsp;<FormGroupItal className="fa fa-paperclip"/>&nbsp;*</FormGroupParagraph><FormGroupTextArea className="form-control" required="" placeholder="Write your message here." minlength="10" onChange={this.handleChange} name="User Message"/>
              <FormGroupButton className="btn btn-primary" type="submit">Contact us</FormGroupButton>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            contactBlockSection {
                contactUsTitle
                contactUsSubTitle
                contactUsSecondaryTitle
                contactUsSecondarySubTitle
                contactUsSecondaryEmail
                contactUsImage {
                    publicURL
                }
            }
          }
        }
      }
    `}
    render={data => <ContactBlock data={data} {...props} />}
  />
);
