import React from 'react';
import styled from 'styled-components';
import { graphql, Link, StaticQuery } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll'
 import logo from '../img/logo.png'

const NavbarOutline = styled.nav`
background-color: ${props => props.sectionEnabled ? props.theme.backgroundColorWhite : props.theme.backgroundColorStandard};
position: absolute;
width: 100%;
z-index: 20;
`;

const themeable = {
  backgroundColorWhite: '#ffffff',
  backgroundColorStandard: 'rgba(254,254,254,0)',
}

const MenuItemsOverlay = styled.div`
  display: block;
  height: ${props => props.active ? 100 : 0};
  width: 100%;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  background-color: white;
  overflow-y: hidden;
  transition: 0.5s;
  -webkit-animation: ${props => props.active ? `slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both` : `slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both`};
  animation: ${props => props.active ? `slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both` :  `slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both`};
`;

const NavLink = styled(AnchorLink)`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: #1f1f1f;
  letter-spacing: 0.1em;
`;

const ExternalNavLink = styled.a`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: #1f1f1f;
  letter-spacing: 0.1em;
`;

const NavigationLinks = styled.a`
  color: #9ea0a6;
  font-weight: 300;
  font-size: 2em;
  text-decoration: none;
`;

const NavigationMobileAnchors = styled(AnchorLink)`
  color: #9ea0a6;
  font-weight: 300;
  font-size: 2em;
  text-decoration: none;
`;

const Row = styled.div`
  padding: 25px 0;
`;

const ClosedLink = styled.a`
  color: #1f1f1f;
  font-weight: 300;
  font-size: 3em;
  width: 100%;
  text-decoration: none;
  cursor: grab;
`;

const NavbarToggleIcon = styled.span``;

const MobileNavigationImage = styled.div``;

const MobileNavigationView = styled.div`
    @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopNavigationView = styled.div`
    @media (max-width: 768px) {
    display: none;
  }

`;

// Constructor Functions

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: "is-active"
          })
          : this.setState({
            navBarActiveClass: ""
          });
      }
    );
  };

  render() {
    const { data } = this.props;
    const {
      facebook,
      instagram,
    } = data.markdownRemark.frontmatter.socialLinks;
    const {
      introBlockVideoToggle
    } = data.markdownRemark.frontmatter.introBlockSection;

    return (
      <NavbarOutline theme={themeable} sectionEnabled={introBlockVideoToggle} className="navbar navbar-light navbar-expand-md navigation-clean-button" style={{position: "initial"}}>
        <div style={{width: "100%"}}>
          {this.state.active ?  <div/> :
          <MobileNavigationView className="row" style={{marginBottom: "5vw"}}>
            <div className="col">
              <MobileNavigationImage>
                <NavLink className="" to="/">
                  <img src={logo} height={50} alt="The Crooked Pine" style={{height: "calc(8vw + 10px)"}}/>
                </NavLink>
              </MobileNavigationImage>
            </div>
            <div className="col">
              <div className="float-right">
                <button
                  data-toggle="collapse"
                  onClick={() => this.toggleHamburger()}
                  className="navbar-toggler"
                  id="nav_menubar1"
                  style={{height: "calc(8vw + 10px)", borderColor: 'transparent' }}
                >
                  <NavbarToggleIcon className="navbar-toggler-icon"/>
                  <span className="sr-only">Toggle navigation</span>
                </button>
              </div>
            </div>
          </MobileNavigationView> }
          {this.state.active ?
              <MenuItemsOverlay
                id="menu_items_overlay"
                onClick={() => this.toggleHamburger()} active={this.state.active}
              >
                <div style={{ height: '100vh' }}>
                  <div
                    className="container"
                    style={{ paddingTop: '50px', paddingLeft: '20px', maxWidth: '576px'}}
                  >
                    <Row className="row">
                      <div className="col-md-12" style={{ textAlign: 'right' }}>
                        <ClosedLink onClick={() => this.toggleHamburger()}>
                          <i className="fa fa-bars" style={{fontSize:" 0.8em",
                            color:" #a5a9ad"}}/>
                        </ClosedLink>
                      </div>
                    </Row>
                    <Row className="row">
                      <div className="col-md-12">
                        <NavigationLinks href="/">
                          Home
                        </NavigationLinks>
                      </div>
                    </Row>
                    <Row className="row">
                      <div className="col-md-12">
                        <NavigationMobileAnchors href="#menu_block">
                          Menus
                        </NavigationMobileAnchors>
                      </div>
                    </Row>
                    <Row className="row">
                      <div className="col-md-12">
                        <NavigationMobileAnchors href="#contact_block">
                          Contact
                        </NavigationMobileAnchors>
                      </div>
                    </Row>
                    <Row className="row">
                      <div className="col-md-12">
                        <NavigationMobileAnchors href="#events_block">
                          Events
                        </NavigationMobileAnchors>
                      </div>
                    </Row>
                    <Row className="row">
                      <div className="col-md-12">
                        <a
                          href={instagram}
                          style={{
                            color: '#9ea0a6',
                            fontWeight: '300',
                            fontSize: '2.0em',
                            textDecoration: 'none',
                          }}
                        >
                          <i className="fa fa-instagram"/>
                        </a>
                        <a
                          href={facebook}
                          style={{
                            color: '#9ea0a6',
                            fontWeight: '300',
                            fontSize: '1.9em',
                            textDecoration: 'none',
                            paddingLeft: '20px',
                          }}
                        >
                          <i className="fa fa-facebook-f"/>
                        </a>
                        <a
                          href="//app.upserve.com/s/crooked-pine-ogunquit"
                          style={{
                            color: '#9ea0a6',
                            fontWeight: '300',
                            fontSize: '1.9em',
                            textDecoration: 'none',
                            paddingLeft: '20px',
                          }}
                        >
                          Order Now
                        </a>
                      </div>
                    </Row>
                  </div>
                </div>
              </MenuItemsOverlay>
            :
            <DesktopNavigationView className="row" style={{height: "75px"}}>
              <div className="col-4">
                <ul className="nav navbar-nav float-left">
                  <li className="nav-item" role="presentation">
                    <NavLink className="nav-link" href="#menu_block">
                      Menus
                    </NavLink>
                  </li>
                  <li className="nav-item" role="presentation">
                    <NavLink className="nav-link" href="#reserve_block">
                      Reserve
                    </NavLink>
                  </li>
                  <li className="nav-item" role="presentation">
                    <ExternalNavLink className="nav-link" href="//app.upserve.com/s/crooked-pine-ogunquit">
                      Order Online
                    </ExternalNavLink>
                  </li>
                </ul>
              </div>
              <div className="col-4">
                <div className="text-center">
                  <Link className="navbar-brand" to="/" id="nav_bar_logo"/>
                </div>
              </div>
              <div className="col-4">
                <ul className="nav navbar-nav float-right">
                  <li className="nav-item" role="presentation">
                    <ExternalNavLink className="nav-link" href="//app.upserve.com/b/crooked-pine/gift-card">
                      Gift Cards
                    </ExternalNavLink>
                  </li>
                  <li className="nav-item" role="presentation">
                    <NavLink className="nav-link" href="#events_block">
                      Events
                    </NavLink>
                  </li>
                  <li className="nav-item" role="presentation">
                    <NavLink className="nav-link" href="#Contact_Block">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>
            </DesktopNavigationView>
          }
        </div>
      </NavbarOutline>
    );
  }
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            introBlockSection {
              introBlockVideoToggle
            }
            socialLinks {
              facebook
              instagram
            }
          }
        }
      }
    `}
    render={data => <Navbar data={data} {...props} />}
  />
);
