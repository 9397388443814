import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

const PostLocation = styled.h6`
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  padding-bottom: 0;
  font-size: 1em;
  color: #18ffc7;
`;

const PostDate = styled.h6`
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 0.9em;
  color: #bbbbbb;
`;

const PostTitle = styled.div`
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  padding-bottom: 15px;
  font-size: 1.6em;
  color: #000000;
`;

const PostArticle = styled.article`
  min-width: 20vw;
`;

class CurrentEventRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <Link data-aos="fade-up" to={post.fields.slug}>
                <PostArticle
                  className={`blog-list-item tile is-child box notification `}
                  style={{ backgroundColor: 'white' }}
                >
                  <header>
                    <div className="post-meta">
                      <PostLocation>{post.frontmatter.location}</PostLocation>
                      <PostTitle>{post.frontmatter.title}</PostTitle>
                      <span className="subtitle is-size-5 is-block">
                        <PostDate>{post.frontmatter.date}</PostDate>
                      </span>
                    </div>
                  </header>
                </PostArticle>
              </Link>
            </div>
          ))}
      </div>
    );
  }
}

CurrentEventRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query CurrentEventRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 4
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                location
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <CurrentEventRoll data={data} count={count} />}
  />
);
