import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

const GalleryBlockContainer = styled.div`
  height: auto;
  padding: 20px;
  z-index: 20;
  background-color: #ffffff;
`;

const GalleryContainer = styled.div`
visibility: inherit;opacity: 1;transform: matrix(1, 0, 0, 1, 0, 0);align-items: flex-start;position: relative;top: 10em;padding-left: 0;padding-right: 15px;position: -webkit-sticky;position: sticky;
`;

const GalleryGroup = styled.div`
  font-family: Merriweather, serif;font-weight: 300;padding: 30px 0px;
`;

const GalleryHeading = styled.h1`
font-weight: 300;font-size: 2.0em;font-family: Poppins, sans-serif;
`;

const GalleryHeadingVersionOne = styled.h1`
color: #9ea0a6;font-weight: 300;font-size: 1.5em;font-family: 'PT Serif', serif;
`;

const GalleryGroupDescription = styled.p`
font-family: Lato, sans-serif;width: 80%;line-height: 2;font-size: 1.4em;padding: 20px 0px;
`;

const GalleryGroupImage = styled.img`
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);padding: 8%;margin: 15% 0;
`;


class GalleryBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {

    const { data } = this.props;
    const {
      galleryTitle,
      gallerySubTitle,
      galleryDescription,
      enabledGalleryImage1,
      galleryImage1,
      enabledGalleryImage2,
      galleryImage2,
      enabledGalleryImage3,
      galleryImage3,
      enabledGalleryImage4,
      galleryImage4,
    } = data.markdownRemark.frontmatter.gallerySection;

    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <div className="GalleryBlockWrapper">
        <GalleryBlockContainer id="gallery_block">
          <div className="container" style={{padding: '10vw 0px 10vw'}}>
              <div className="row">
                  <div className="col-md-6" style={{fontWeight: '300'}}>
                      <GalleryContainer>
                          <GalleryGroup>
                              <GalleryHeading data-aos="fade-up">{galleryTitle}<br/></GalleryHeading>
                              <GalleryHeadingVersionOne data-aos="fade-up">{gallerySubTitle}<br/></GalleryHeadingVersionOne>
                          </GalleryGroup>
                          <GalleryGroupDescription data-aos="fade-up-right">{galleryDescription}</GalleryGroupDescription>
                      </GalleryContainer>
                  </div>
                  <div className="col-md-6">
                      <div style={{display: 'block',textAlign: 'center'}}>
                        { enabledGalleryImage1 ? <GalleryGroupImage data-aos="fade" src={galleryImage1.publicURL} width="100%"/>: <div/> }
                        { enabledGalleryImage2 ? <GalleryGroupImage data-aos="fade" src={galleryImage2.publicURL} width="100%"/>: <div/> }
                        { enabledGalleryImage3 ? <GalleryGroupImage data-aos="fade" data-aos-offset="50%" src={galleryImage3.publicURL} width="100%"/>: <div/>}
                        { enabledGalleryImage4 ? <GalleryGroupImage data-aos="fade" data-aos-offset="50%" src={galleryImage4.publicURL} width="100%"/>: <div/>}
                      </div>
                  </div>
              </div>
          </div>
        </GalleryBlockContainer>
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            gallerySection {
                galleryTitle
                gallerySubTitle
                galleryDescription
                enabledGalleryImage1
                galleryImage1 {
                    publicURL
                }
                enabledGalleryImage2
                galleryImage2 {
                    publicURL
                }
                enabledGalleryImage3
                galleryImage3 {
                    publicURL
                }
                enabledGalleryImage4
                galleryImage4 {
                    publicURL
                }
            }
          }
        }
      }
    `}
    render={data => <GalleryBlock data={data} {...props} />}
  />
);
