import styled from 'styled-components';

export const RestaurantBlockContainer = styled.div`
font-family: 'Merriweather', serif;font-weight: 300;padding: 30px 0px;
`;

export const RestaurantBlockContainerHeadingOne = styled.h1`
color: #9ea0a6;font-weight: 300;font-size: 1.5em;font-family: 'PT Serif', serif;
`;

export const RestaurantBlockContainerHeadingSix = styled.h6`
font-weight: 300;font-size: 2.0em;font-family: Poppins, sans-serif;
`;

