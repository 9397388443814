import React, { PureComponent } from 'react';
import styled from 'styled-components';
import CurrentEventRoll from '../CurrentEventRoll'; // This worked!!
import PastEventRoll from '../PastEventRoll';
import { graphql, StaticQuery } from 'gatsby';

// Styled Setup
const EventsHeader = styled.h1`
  font-size: 4em;
  font-family: 'Poppins', sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CurrentEventsHeader = styled.div`
  font-family: 'Merriweather', serif;
  font-weight: 300;
`;

const CurrentEventHeaderText = styled.h1`
  font-weight: 300;
  font-family: 'PT Serif', serif;
  font-size: 250%;
`;

const PastEventsText = styled.div`
  font-family: 'Merriweather', serif;
  font-weight: 300;
  padding: 30px 0;
`;

const PastEventsHeaderText = styled.h1`
  font-weight: 300;
  font-family: 'PT Serif', serif;
  font-size: 250%;
`;

class EventsBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {

    const { data } = this.props;
    const {
      eventsBlockTitle,
      eventsBlockTitleImage,
      eventsBlockCurrentEventsTitle,
      eventsEnablePastEvents,
      eventsBlockPastEventsTitle,
      eventsBlockPastEventsImage,
    } = data.markdownRemark.frontmatter.eventsBlockSection;

    if (this.state.hasError) {
      return <h1>Someone should really fix this mess above me.</h1>;
    }
    return (
      <div className="EventsBlockWrapper">
        <div
          id="events_block"
          style={{ backgroundColor: '#FFF', maxWidth: 'none' }}
        >
          <div className="container" style={{ maxWidth: 'none'}}>
            <div className="row" style={{ maxWidth: 'none', paddingBottom: '10vw' }}>
              <div className="col" style={{padding: "1vw 0"}}>
                <div
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                    color: 'white',
                  }}
                >
                  <EventsHeader className="text-left">
                    {eventsBlockTitle}
                    <br />
                  </EventsHeader>
                  <img
                    alt="Event"
                    src={eventsBlockTitleImage.publicURL}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '40vh',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row" id="past_events">
              <div className="col-md-12">
                <CurrentEventsHeader>
                  <CurrentEventHeaderText
                    className="text-center"
                    data-aos="fade-up-right"
                  >
                    {eventsBlockCurrentEventsTitle}
                    <br />
                  </CurrentEventHeaderText>
                </CurrentEventsHeader>
              </div>
            </div>
            <div
              className="row"
              id="current_events_cms"
              style={{ maxWidth: 'none' }}
            >
              <div className="col" style={{ display: 'inline-flex' }}>
                <div>
                  <div
                    className="row"
                    data-aos="fade-up"
                    style={{ padding: '2vw 0vw' }}
                  >
                    {/*<EventComponent /> */}
                    <CurrentEventRoll />
                  </div>
                </div>
              </div>
            </div>
            { eventsEnablePastEvents ?
            <div>
              <div className="row" id="past_events">
                <div className="col-md-12">
                  <PastEventsText>
                    <PastEventsHeaderText
                      className="text-center"
                      data-aos="fade-up-right"
                    >
                      {eventsBlockPastEventsTitle}
                      <br />
                    </PastEventsHeaderText>
                  </PastEventsText>
                </div>
              </div>
              <div className="row" id="past_events_cms" style={{ maxWidth: 'none' }}>
                <div className="col" style={{ display: 'inline-flex' }}>
                  <div>
                    <div
                      className="row"
                      data-aos="fade-up"
                      style={{ padding: '2vw 0vw' }}
                    >
                      <div
                        className="col-md-6"
                        style={{ backgroundColor: '#FFF' }}
                      >
                        <img
                          alt="Secondary Event"
                          src={eventsBlockPastEventsImage.publicURL}
                          style={{
                            height: '100%',
                            objectFit: 'cover',
                            marginLeft: '-50px',
                          }}
                          width="100%"
                        />
                      </div>
                      <div
                        className="col-md-6"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          backgroundColor: '#FFFFFF',
                        }}
                      >
                        {/* Place the Past Roll here */}
                        <PastEventRoll />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> : <div/>}
          </div>
        </div>
      </div>
    );
  }
}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            eventsBlockSection {
                eventsBlockTitle
                eventsBlockTitleImage {
                    publicURL
                }
                eventsBlockCurrentEventsTitle
                eventsEnablePastEvents
                eventsBlockPastEventsTitle
                 eventsBlockPastEventsImage {
                    publicURL
                }
            }
          }
        }
      }
    `}
    render={data => <EventsBlock data={data} {...props} />}
  />
);
