
import styled from 'styled-components';

export const Overlay = styled.div`
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: white;
  overflow-y: hidden;
  transition: 0.5s;
`

export const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IntroContainer = styled.div``

export const IntroContainerBlock = styled.div`
  background-color: #ffffff;
  margin-top: -30px;
`

export const IntroBlockColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  padding-bottom: 100px;
`

export const AboutIntroBlock = styled.div`
border: 1px solid rgb(209,209,209);display: inline-block;width: 50px;margin-right: 30px;box-shadow: 0 0 0 .2rem rgba(252,252,252,0.17);height: 0px;margin-bottom: 5px;
`

export const AboutIntroLink = styled.a`
  text-decoration: none !important;
  color: #3c4146;
  font-family: 'Poppins', sans-serif;
`

export const AboutIntroTextSpan = styled.span`
  text-decoration: none !important;
  color: #3c4146;
  font-family: 'Poppins', sans-serif;
`

export const AboutIntroButton = styled.span`
height: 50px !important;
margin-left: 20px !important;
width: 50px !important;
border-radius: 25px !important;
position: relative !important;
background-color: #18ffc7 !important;
border-color: #18ffc7 !important;
box-shadow: 0 0 0 .2rem rgba(24,255,199,0.17) !important;
`

export const AboutIntroIStyling = styled.i`
  color: #FFFFFF;
  position: absolute;
  top: 50%;left: 50%;
  transform: translate(-50%, -50%);
`

export const VideoContainerBlock = styled.div`
  text-align: center !important;
  z-index: 10 !important;
  position: absolute !important;
  top: 25% !important;
  left: 0 !important;
  width: 100% !important;
`

export const VideoContainerBlockButton = styled.button`
  background-color: rgba(250,250,250,0.32) !important;
  height: 60px !important;
  border-radius: 25px !important;
  width: 110px !important;
  border: none rgba(136,135,129,0.0) !important;
  box-shadow: inset 0 0 0 2px #000 !important;
  color: #ffffff !important;
`
