import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import Footer       from '../components/Footer';
import Navbar       from '../components/Navbar';
import AboutBlock   from '../components/AboutBlock/AboutBlock';
import ContactBlock from '../components/ContactBlock/ContactBlock';
import EventsBlock  from '../components/EventsBlock/EventsBlock';
import GalleryBlock from '../components/GalleryBlock/GalleryBlock';
import IntroBlock   from '../components/IntroBlock/IntroBlock';
import MenuBlock    from '../components/MenuBlock/MenuBlock';
import ReserveBlock from '../components/ReserveBlock/ReserveBlock';
import RestaurantBlock from '../components/ResturantBlock/ResturantBlock';
import AosHandler   from '../components/AsosHandler';
import IntroAnnouncementContainer from '../components/IntroAnnouncementContainer/IntroAnnouncementContainer';
import useSiteMetadata from './SiteMetadata';
import './all.sass';
import './css/bootstrap.min.css'
import 'aos/dist/aos.css';
import '../components/css/styles.css'
import 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css'
import './css/bootstrap.min.css'
import './css/styles.css'
import 'font-awesome/css/font-awesome.min.css'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()

  const { markdownRemark } = useStaticQuery(graphql`
    query NavigationToggles {
      markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
        frontmatter {
          toggleViews {
            bannerBlock
            introBlock
            aboutBlock
            galleryBlock
            menuBlock
            reserveBlock
            contactBlock
            eventsBlock
            restaurantBlock
          }
        }
      }
    }
  `);

  const {
    bannerBlock,
    introBlock,
    aboutBlock,
    galleryBlock,
    menuBlock,
    reserveBlock,
    contactBlock,
    eventsBlock,
    restaurantBlock,
  } = markdownRemark.frontmatter.toggleViews;

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#ffffff"/>
        <meta name="google-site-verification" content="o11oIq8h99Ic_6JAAwOr66PJ-8IwOTqoZVgbgFq-ngs" />
        <link href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Merriweather:wght@300;400;700&family=PT+Serif:wght@400;700&family=Poppins:wght@300;400;500;700&display=swap" rel="stylesheet"/>
        <meta property="og:type" content="website"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Crooked Pine"/>
        <meta property="og:image" content="/img/logo.png"/>
        <meta name="twitter:image" content="/img/logo.png"/>
        <meta name="theme-color" content="#273268"/>
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="../img/logo.png" />
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no"/>
        <meta name="twitter:image" content="../assets/img/logo.png"/>
        <meta name="twitter:description"
            content=" Experience Alfresco dining from The Crooked Pine with 360 degree views featuring on-trend oysters and lite bites in the heart of Ogunquit, ME."/>
        <meta property="og:type" content="website"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="The Crooked Pine"/>
      </Helmet>
      <AosHandler/>
      {bannerBlock ? <IntroAnnouncementContainer/> : <div/> }
      <Navbar />
      {introBlock ? <IntroBlock/> : <div/> }
      {aboutBlock ? <AboutBlock/> : <div/> }
      {galleryBlock ? <GalleryBlock/> : <div/> }
      {menuBlock ? <MenuBlock/> : <div/> }
      {reserveBlock ?  <ReserveBlock/> : <div/> }
      {contactBlock ? <ContactBlock/> : <div/> }
      {eventsBlock ?  <EventsBlock/> : <div/> }
      {restaurantBlock ? <RestaurantBlock/> : <div/> }
      <Footer />
    </div>
  )
}

export default TemplateWrapper
