import React, { PureComponent } from "react";
import { NotificationContainer, WhiteWrapper, WhiteButtonWrapper } from  "./IntroAnnouncementContainer.styles.js"
import {graphql, StaticQuery} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";

class IntroAnnouncementContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            showBanner: true
        };
    }

    componentDidMount() {
        const { data } = this.props;
        const { frontmatter } = data.markdownRemark;

        let status = localStorage.getItem(frontmatter.announcementMessage);
        this.setState( {
            showBanner: !status
        })
    }

    dismissBanner(dataItem) {
        localStorage.setItem(dataItem, "false")
        this.setState( {
            showBanner: false
        })
    }


    render() {
        const { data } = this.props;
        const {
            announcementMessage,
            announcementImage
        } = data.markdownRemark.frontmatter.bannerBlockSection;

        if (this.state.hasError) {
            return (
                <h1>Who ever developed this code doesn't know what he's doing. <span role="img" aria-label="shrug">🤷</span></h1>
            );
        }
        return (
            <div>
                { this.state.showBanner ?   <NotificationContainer className="row">
                    <div className="col-2"/>
                    <div className="col">
                        <WhiteWrapper target="_blank" rel="noopener noreferrer" href={announcementImage.publicURL}>
                            <div>{announcementMessage}</div>
                        </WhiteWrapper>
                    </div>
                    <div className="col-2">
                        <WhiteButtonWrapper href="#opener" onClick={() => this.dismissBanner(announcementMessage)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </WhiteButtonWrapper>
                    </div>
                </NotificationContainer> : null
                }
            </div>

        );
    }
}


export default props => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            bannerBlockSection {
                announcementImage {
                  publicURL
                }
                announcementMessage
                }
          }
        }
      }
    `}
        render={data => <IntroAnnouncementContainer data={data} {...props} />}
    />
);

