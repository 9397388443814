import React, { PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ReserveParagraph, ReserveParagraphVersion1,
  OpenTableLink, OpenTableLinkage, ReserveHeadingOne,
  ReserveHeadingOneVersionOne, ReserveParagraphVersion2,TextRightColumn,TextLeftColumn } from './ReserveBlock.styles';



class ReserveBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {
    const { data } = this.props;
    const {
      reserveTitle,
      reserveSubtitle,
      reserveHoursHeader,
      reserveDaysSection1,
      reserveHoursOfOperations,
      reserveDaysSection2,
      reserveClosedOperations,
      reserveAddress,
      reserveRoad,
      reserveZip,
      reserveReservationsTitle,
      reserveReservationsText,
      reserveServiceLink,
      reserveResyButtonText,
      reserveButtonEnabled,
      reserveShowingLunchHours,
      reserveDaysSection3,
      reserveLunchHours,
    } = data.markdownRemark.frontmatter.reservationBlockSection;

    if (this.state.hasError) {
      return <h1>We are probably down for maintenance, I think...</h1>;
    }
    return (
      <div className="ReserveBlockWrapper">
        <div id="reserve_block" style={{ backgroundColor: '#272c31' }}>
          <div
            className="container"
            style={{ backgroundColor: '#272c31', color: '#FFF' }}
          >
            <div
              className="row"
              style={{ padding: '7.5vw 0' }}
            >
              <div className="col">
                <ReserveHeadingOne className="text-center" data-aos="fade-up">
                  {reserveTitle}
                </ReserveHeadingOne>
                <ReserveHeadingOneVersionOne
                  className="text-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {reserveSubtitle}
                </ReserveHeadingOneVersionOne>
              </div>
            </div>
            <div className="row" style={{ paddingBottom: '10vw' }}>
              <TextLeftColumn className="col-xl-6">
                <div style={{ margin: '0 auto'}}>
                  <ReserveParagraph data-aos="fade-up">
                    {reserveHoursHeader}
                  </ReserveParagraph>
                  <div>
                    <div data-aos="fade-up" data-aos-duration="1000"
                         data-aos-delay="250">
                      <div style={{ paddingTop: '' }}>
                        <ReserveParagraphVersion1>
                          <strong>{reserveDaysSection1}</strong>
                        </ReserveParagraphVersion1>
                        <ReserveParagraphVersion2>
                          {reserveHoursOfOperations}
                        </ReserveParagraphVersion2>
                      </div>
                    </div>
                    {
                      reserveShowingLunchHours ?
                        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                          <div style={{ paddingTop: '15px' }}>
                            <ReserveParagraphVersion1>
                              <strong>{reserveDaysSection3}</strong>
                            </ReserveParagraphVersion1>
                            <ReserveParagraphVersion2>
                              {reserveLunchHours}
                            </ReserveParagraphVersion2>
                          </div>
                        </div> :
                        null
                    }
                    <div style={{ top: '0' }}/>
                    <div style={{ paddingTop: '25px' }} data-aos="fade-up" data-aos-duration="1000"
                         data-aos-delay="500">
                      <ReserveParagraphVersion1>
                        <strong>{reserveDaysSection2}</strong>
                      </ReserveParagraphVersion1>
                      <ReserveParagraphVersion2>
                        {reserveClosedOperations}
                      </ReserveParagraphVersion2>
                    </div>
                  </div>
                  <div data-aos="fade-up" data-aos-duration="1000"
                       data-aos-delay="750" style={{ paddingTop: '50px' }}>
                    <ReserveParagraphVersion1>
                      <strong>{reserveAddress}</strong>
                    </ReserveParagraphVersion1>
                    <ReserveParagraphVersion2>
                      {reserveRoad}
                    </ReserveParagraphVersion2>
                    <ReserveParagraphVersion2>
                      {reserveZip}
                    </ReserveParagraphVersion2>
                  </div>
                </div>
              </TextLeftColumn>
              <TextRightColumn className="col-xl-6">
                <div style={{ margin: '0 auto'}}>
                  <div style={{ paddingBottom: '50px' }}>
                    <ReserveParagraph
                      data-aos="fade-up"
                    >
                      {reserveReservationsTitle}
                    </ReserveParagraph>
                    <div style={{ top: '0', paddingBottom: '15px' }}>
                      <ReserveParagraphVersion2
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="250"
                      >
                        {reserveReservationsText}
                      </ReserveParagraphVersion2>
                    </div>
                    { reserveButtonEnabled ?
                      <OpenTableLinkage data-aos="fade-up" data-aos-duration="1000" data-aos-delay="750">
                      <OpenTableLink
                        className=""
                        role="button"
                        href={reserveServiceLink}
                      >
                        <i
                          className="fa fa-opencart"
                          style={{ paddingRight: '10px' }}
                        />
                        {reserveResyButtonText}
                      </OpenTableLink>
                    </OpenTableLinkage> : <div/>
                    }
                  </div>
                </div>
              </TextRightColumn>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            reservationBlockSection{
                reserveTitle
                reserveSubtitle
                reserveHoursHeader
                reserveDaysSection1
                reserveHoursOfOperations
                reserveDaysSection2
                reserveClosedOperations
                reserveShowingLunchHours
                reserveDaysSection3
                reserveLunchHours
                reserveAddress
                reserveRoad
                reserveZip
                reserveReservationsTitle
                reserveReservationsText
                reserveServiceLink
                reserveResyButtonText
                reserveButtonEnabled
            }
          }
        }
      }
    `}
    render={data => <ReserveBlock data={data} {...props} />}
  />
);
